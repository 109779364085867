import React from 'react';
import throttle from 'lodash.throttle';

import {Input} from './input';
import {LoadingSpinner} from './loading-spinner';

export type SearchInputRef = {
    clearSearch: () => void;
};

type SearchInputProps = {
    name: string;
    minCharacters: number;
    onPerformSearch: (query: string) => void;
    loading?: boolean;
    throttleSearch?: number | false;
    placeholder?: string;
    onPressSearchIcon?: () => void;
    initialSearch?: string;
    onFocus?: () => void;
    onBlur?: () => void;
};

export const SearchInput = React.forwardRef<SearchInputRef, SearchInputProps>(function SearchInput(
    {
        name,
        minCharacters,
        onPerformSearch,
        loading,
        throttleSearch = 500,
        placeholder = 'Search',
        onPressSearchIcon,
        initialSearch,
        onFocus,
        onBlur,
    },
    ref,
) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [query, setQuery] = React.useState('');
    const performSearch = React.useMemo(() => {
        if (throttleSearch) {
            return throttle(onPerformSearch, throttleSearch);
        } else {
            return onPerformSearch;
        }
    }, [onPerformSearch, throttleSearch]);
    React.useImperativeHandle(
        ref,
        () => ({
            clearSearch: () => {
                setQuery('');
                performSearch('');
            },
        }),
        [setQuery, performSearch],
    );

    React.useEffect(() => {
        if (query.length < minCharacters) {
            return;
        }

        performSearch(query);
    }, [query, performSearch]);

    React.useEffect(() => {
        setQuery(initialSearch || '');
        if (initialSearch != null && inputRef.current != null) {
            inputRef.current.focus();
        }
    }, [initialSearch, setQuery]);

    const pressIcon = () => {
        if (onPressSearchIcon == null || loading || query.length < minCharacters) {
            return;
        }

        onPressSearchIcon();
    };

    let icon;
    if (loading) {
        icon = <LoadingSpinner />;
    }

    return (
        <Input
            ref={inputRef}
            name={name}
            placeholder={placeholder}
            type="search"
            value={query}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
            icon={icon}
            onPressIcon={pressIcon}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
});
