import React from 'react';
import {useButton} from '@react-aria/button';
import classnames from 'classnames';

import {useLink} from './link';
import {LoadingSpinner} from './loading-spinner';
import {
    Background,
    Text,
    bgColorClassnames,
    outlineColorClassnames,
    textColorClassnames,
    Border,
    getTextColorBasedOnOutline,
} from './utils';
import {intercomTargetProps} from './intercom-target';
import {ButtonLabel} from './button-label';

type UseButtonProps = Parameters<typeof useButton>[0];
export type ButtonProps = Pick<JSX.IntrinsicElements['button'], 'type' | 'children'> &
    Pick<UseButtonProps, 'onPress'> & {
        isLoading?: boolean;
        isDisabled?: boolean;
        outline?: boolean;
        textColor?: Text;
        outlineColor?: Border;
        icon?: React.ReactNode;
        skeleton?: boolean;
        bg?: Background;
        intercomTarget?: string;
        small?: boolean;
        testId?: string;
        className?: string;
    };
export function Button({
    children,
    onPress,
    type,
    isLoading,
    isDisabled,
    outline,
    textColor = 'white',
    outlineColor = 'major-action',
    icon,
    skeleton,
    small,
    bg = 'major-action',
    testId = 'button',
    className,
    intercomTarget,
}: ButtonProps) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const disabled = isLoading || isDisabled || skeleton;
    const {buttonProps} = useButton(
        {
            type,
            onPress,
            children,
            isDisabled: disabled,
        },
        buttonRef,
    );

    return (
        <button
            data-testid={testId}
            ref={buttonRef}
            {...buttonProps}
            className={classnames(
                className,
                'px-12 py-2 min-w-min ',
                'whitespace-nowrap rounded-full focus:outline-none focus:ring relative',
                !disabled &&
                    !skeleton &&
                    !outline &&
                    `${bgColorClassnames(bg)} ${textColorClassnames(textColor)}`,
                !skeleton &&
                    outline &&
                    `${outlineColorClassnames(outlineColor)} ${textColorClassnames(
                        getTextColorBasedOnOutline(outlineColor),
                    )}`,
                disabled &&
                    !skeleton &&
                    !outline &&
                    bg !== 'transparent' &&
                    `${bgColorClassnames('disabled')} ${textColorClassnames(
                        'disabled',
                    )} cursor-not-allowed`,
                disabled &&
                    !skeleton &&
                    !outline &&
                    bg === 'transparent' &&
                    `${textColorClassnames('disabled')} cursor-not-allowed`,
                {
                    'hover:bg-action-major-600 ':
                        !disabled && !skeleton && !outline && bg !== 'transparent',
                    'hover:bg-transparent': bg === 'transparent',
                    'bg-action-minor-200 text-transparent': skeleton,
                    'border-2': outline,
                    'w-40': !small,
                },
            )}
            {...intercomTargetProps(intercomTarget)}
        >
            <ButtonLabel inherit>
                {children}
                {isLoading && (
                    <div
                        className="absolute flex items-center justify-center top-0 bottom-0 right-2"
                        data-testid="loading-spinner"
                    >
                        <LoadingSpinner size={18} />
                    </div>
                )}
                {icon && (
                    <div className="absolute flex items-center justify-center top-0 bottom-0 left-2 w-5">
                        {icon}
                    </div>
                )}
            </ButtonLabel>
        </button>
    );
}

export type ButtonLinkProps = Pick<
    ButtonProps,
    'children' | 'skeleton' | 'onPress' | 'textColor' | 'outlineColor' | 'bg' | 'intercomTarget'
> & {
    href: string;
    outline?: boolean;
    openInNewWindow?: boolean;
};
export function ButtonLink({
    href,
    onPress,
    skeleton,
    outline,
    children,
    bg = 'major-action',
    textColor = 'white',
    outlineColor = 'white',
    openInNewWindow = false,
    intercomTarget,
}: ButtonLinkProps) {
    const linkProps = useLink({
        onPress,
        href,
        children,
        openInNewWindow,
    });

    return (
        <a
            {...linkProps}
            {...intercomTargetProps(intercomTarget)}
            className={classnames(
                'block px-12 rounded-full focus:outline-none focus:ring text-center',
                !outline &&
                    !skeleton &&
                    `hover:bg-action-major-600 ${bgColorClassnames(bg)} ${textColorClassnames(
                        'white',
                    )}`,
                outline &&
                    ` bg-transparent border-2 py-1 ${textColorClassnames(
                        textColor,
                    )} ${outlineColorClassnames(outlineColor)}`,
                {
                    'py-2': !outline,
                    'bg-yin-90 text-transparent': skeleton,
                },
            )}
        >
            <ButtonLabel inherit>{children}</ButtonLabel>
        </a>
    );
}

export function TextButton({
    onPress,
    isDisabled,
    isLoading,
    type,
    textColor = 'minor-action',
    supportsLoading,
    noPadding = false,
    uppercase = false,
    children,
}: Pick<ButtonProps, 'onPress' | 'isDisabled' | 'isLoading' | 'type' | 'children' | 'textColor'> & {
    supportsLoading?: boolean;
    noPadding?: boolean;
    uppercase?: boolean;
}) {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const showDisabledStyle = isLoading || isDisabled;
    const {buttonProps} = useButton(
        {
            onPress,
            children,
            isDisabled,
            type,
        },
        buttonRef,
    );
    return (
        <button
            ref={buttonRef}
            {...buttonProps}
            className={classnames(
                'focus:outline-none focus:ring relative',
                !showDisabledStyle && textColorClassnames(textColor),
                showDisabledStyle && `cursor-not-allowed ${textColorClassnames('disabled')}`,
                {
                    uppercase: uppercase,
                    'p-2': !noPadding,
                    'px-6': supportsLoading,
                    'pointer-events-none': onPress == null,
                },
            )}
        >
            <ButtonLabel inherit>
                {children}
                {isLoading && (
                    <div className="absolute flex items-center justify-center top-0 bottom-0 right-0">
                        <LoadingSpinner size={18} />
                    </div>
                )}
            </ButtonLabel>
        </button>
    );
}
