import React from 'react';
import classnames from 'classnames';

import {useLink, PressEvent, OnPressHandler} from './link';
import {intercomTargetProps} from './intercom-target';
import {Background, bgColorClassnames, textColorClassnames, Text} from './utils';
import {Heading5} from './heading-5';

export type NavLinkProps = {
    href: string;
    icon: React.ReactNode;
    active?: boolean;
    children?: React.ReactNode;
    onPress?: (e: PressEvent, href: string) => void;
    openInNewWindow?: boolean;
    intercomTarget?: string;
    transparentBackground?: boolean;
    iconBg?: Background;
    iconColour?: Text;
    linkTextColour?: Text;
    useButtonLabel?: boolean;
};

export function NavLink({
    href,
    icon,
    onPress,
    children,
    openInNewWindow,
    iconBg = 'transparent',
    iconColour = 'white',
    linkTextColour = 'white',
    intercomTarget,
}: NavLinkProps) {
    const linkProps = useLink({
        children,
        href,
        onPress: onPress as OnPressHandler,
        openInNewWindow,
    });

    let content = children;
    if (typeof children === 'string') {
        content = <Heading5 colour={linkTextColour}>{children}</Heading5>;
    }

    return (
        <a
            className="flex items-center space-x-2 pr-5"
            {...intercomTargetProps(intercomTarget)}
            {...linkProps}
        >
            <div
                className={classnames(
                    'flex shrink-0 items-center justify-center h-10 w-10 rounded-full',
                    `${bgColorClassnames(iconBg)}`,
                )}
            >
                <div
                    className={classnames(
                        'w-6 h-6 flex justify-center items-center',
                        `${textColorClassnames(iconColour)}`,
                    )}
                >
                    {icon}
                </div>
            </div>
            {content && <div className="pl-2 whitespace-nowrap">{content}</div>}
        </a>
    );
}
